<template>
  <allOrder orderType="" :isVisit="true" />
</template>

<script>
import allOrder from '@/views/work-order/all-order'
export default {
  components: {
    allOrder
  }
}
</script>
